html,
body,
#root {
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

#root {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
}

header,
footer {
	display: flex;
	flex-shrink: 0;
}

main {
	flex: 1;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}

/* Firebase login UI styling */
#firebaseui-auth-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.firebaseui-container {
	width: 100%;
}

.firebaseui-idp-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px 20px;
	margin: 10px 0;
	font-size: 16px;
	font-weight: bold;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	position: relative;
	overflow: hidden;
}

.firebaseui-idp-button::before {
	content: attr(title);
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
}

.firebaseui-idp-text-short {
	display: none;
}

.firebaseui-idp-google {
	background-color: white;
	color: black;
	border: 1px solid black;
}

.firebaseui-idp-facebook {
	background-color: #3b5998;
}

.firebaseui-idp-facebook > span,
.firebaseui-idp-twitter > span {
	color: white;
}

.firebaseui-idp-twitter {
	background-color: #1da1f2;
	color: white;
}

.firebaseui-idp-button:hover {
	opacity: 0.9;
}

.firebaseui-idp-icon {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
}

@tailwind base;

@font-face {
	font-family: "Satoshi";
	src: url("./assets/fonts/Satoshi-Regular.woff2") format("woff2"),
		url("./assets/fonts/Satoshi-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Satoshi";
	src: url("./assets/fonts/Satoshi-Bold.woff2") format("woff2"),
		url("./assets/fonts/Satoshi-Bold.woff") format("woff");
	font-weight: 600;
	font-style: normal; /* Change to normal instead of bold */
}

@font-face {
	font-family: "General Sans";
	src: url("./assets/fonts/GeneralSans-Regular.woff2") format("woff2"),
		url("./assets/fonts/GeneralSans-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "General Sans";
	src: url("./assets/fonts/GeneralSans-Semibold.woff2") format("woff2"),
		url("./assets/fonts/GeneralSans-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal; /* Change to normal instead of bold */
}

@tailwind components;

@layer base {
	.font-general-sans {
		font-family: "General Sans", sans-serif;
		font-weight: 400; /* Ensure regular weight */
	}
	.font-general-sans-semibold {
		font-family: "General Sans", sans-serif;
		font-weight: 600; /* Semibold weight */
	}
	.font-satoshi {
		font-family: "Satoshi", sans-serif;
		font-weight: 400; /* Ensure regular weight */
	}
	.font-satoshi-semibold {
		font-family: "Satoshi", sans-serif;
		font-weight: 600; /* Semibold weight */
	}
}

@layer components {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-satoshi;
		@apply font-satoshi-semibold;
		@apply text-text-primary;
	}
	p,
	* {
		@apply font-satoshi;
		@apply text-text-primary;
	}
}

h1 {
	@apply text-2xl;
}
h2 {
	@apply text-xl;
}
h3 {
	@apply text-lg;
}
a {
	@apply text-blue-600;
}

header,
main {
	@apply p-4;
}

footer {
	@apply p-4;
	@apply bg-white;
	@apply flex-col;
	@apply gap-4;
}

@media (min-width: 769px) {
	.desktop-box-shadow {
		box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
			var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
		--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
			0 4px 6px -4px rgba(0, 0, 0, 0.1);
		--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
			0 4px 6px -4px var(--tw-shadow-color);
	}
}

.brand-gradient-background {
	background: linear-gradient(199deg, #842ed8, #db28a9, #9d1dca);
}

.gray-faded-bg {
	border: 1px solid var(--bb_back, rgba(226, 234, 245, 0.5));
	background: rgba(226, 234, 245, 0.35);
}

.progress-bar {
	transition: width 0.1s linear; /* Adjust transition timing to match the interval */
}

header.backgrounded {
	border-radius: 0px 0px 20px 20px;
	border-bottom: 1px solid rgb(253, 229, 234);
	background: var(--bb_back2, rgba(253, 229, 234, 0.5));
}

footer.with-shadow {
	border-top-width: 0px !important;
	background: linear-gradient(
		0deg,
		#fff 4%,
		rgba(255, 255, 255, 0.43) 112.5%
	);
	box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.07);
	/* backdrop-filter: blur(20px);
	--webkit-backdrop-filter: blur(20px); */
}

@media (max-width: 768px) {
	footer {
		padding-bottom: 5rem !important;
	}
}

.custom-textarea::placeholder {
	text-align: center;
	color: #71717a;
	white-space: pre-wrap;
}
.custom-textarea:focus {
	border-color: #090a0b;
	border-bottom-width: 2px;
	outline: none;
}

a.primary,
a.secondary,
button.primary,
button.secondary {
	@apply rounded-2xl;
	@apply w-full;
}

a.primary,
button.primary {
	@apply brand-gradient-background;
	@apply text-white;
	@apply font-satoshi-semibold;
	@apply text-center;
}

a.secondary,
button.secondary {
	@apply rounded-2xl;
	@apply border;
	@apply border-2;
	@apply border-theme-brand-purple;
	@apply text-theme-brand-purple;
	@apply font-satoshi-semibold;
	@apply text-center;
}

@tailwind utilities;

/* answer button states */

.quiz-answer-option {
	border: 3px solid rgba(226, 234, 245, 0.5);
	background: rgba(226, 234, 245, 0.5);
}

.quiz-answer-option.selected {
	border: 1px solid #db28a9;
	background: rgba(253, 229, 234, 0.15);
}

.quiz-answer-option.correct {
	background: #1ac321;
	font-weight: 600;
	color: white;
}

.quiz-answer-option.selected.incorrect {
	border: 2px solid #f04438;
	background: rgba(255, 197, 197, 0.15);
}
